import { SEO } from '~modules'
/* @TODO: fix alias, ~components not working */
import { components } from '../components'
import { JsonLD } from '~elements'
import { Page as DefaultPage } from '~modules'
import { fetchPageBySlug } from '../lib/hygraph'
import { REVALIDATION_INTERVAL, SLUG_HOMEPAGE } from '~constants'
import { Fragment } from 'react'

interface StoryInterface {
  name: string
  content: object
}

interface PageProps {
  story: StoryInterface
  preview: boolean
}

export default function Home({ story, preview }: PageProps) {
  const { content } = story
  const { body } = content
  const { seo, title } = body

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: 'Sphere',
    url: 'https://www.thespherevegas.com/'
  }

  return (
    <Fragment>
      <JsonLD data={structuredData} />
      <SEO defaultTitle={title} {...seo} />
      <DefaultPage blok={content} components={components} preview={preview} />
    </Fragment>
  )
}

export async function getStaticProps({ preview = false }) {
  const data = await fetchPageBySlug(preview, { slug: SLUG_HOMEPAGE })

  return {
    props: {
      story: data ? { content: { body: { ...data.pages[0] } } } : false,
      preview
    },
    revalidate: REVALIDATION_INTERVAL
  }
}
